import logo from './logo.svg';
import React, { useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  let [messages, setMessages] = React.useState('hello world');
  let [counter, setCounter] = React.useState(0);
  return (
    <>
      <span>{messages} this is a react test {counter}</span>
      <button onClick={() => setCounter(counter + 1)}>Increment</button>
    </>
  );
}

export default App;
